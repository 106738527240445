
@import "@variables";

.export-wrapper {
  .export {
    height: 40px;
    margin: 20px 0 !important;
    width: 124px;
    border-radius: 20px;
    background: $gradient-start !important;
    box-shadow: none !important;

    h4 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 13px;
    }
  }
}
