
.permissions-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)) !important;
  grid-row-gap: 20px;
}

.resource-name {
  flex-direction: column;
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}
