
@import "@variables";

.rent-tariffs-view {
  .how-to-read-pricing {
    padding: 16px;
    background: #fff;
    border-radius: 14px;
    align-items: center;

    span {
      display: block;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;
      color: #1c2567;

      i {
        margin-left: 16px;
      }
    }

    .content {
      margin-top: 16px;

      p {
        margin: 0;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        color: #000;
        font-weight: 300;
        margin-bottom: 8px;
        font-family: $avenir-next-regular;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
