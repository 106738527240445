
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;

    .el-col {
      width: 100%;
    }
  }
}
