
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  // border-style: solid;
  // border: 1px solid black;
  // box-shadow: 1px 4px 5px 4px rgb(238, 238, 238);
  box-shadow: 0px 0px 5px 3px rgb(238, 238, 238);
  padding: 10px;
  padding-left: 15px; /* Add left padding to create a gap */
  position: absolute;
  z-index: 100;
  bottom: 110%; /* Position the tooltip above the text */
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  font-family: "AvenirNextW10Medium", sans-serif;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -9px;
  border-width: 7px;
  border-style: solid;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.status-wrapper {
  display: flex;
  flex-direction: column;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
