
.view-bike-details {
  .details-bold .el-col span {
    font-weight: bolder;
  }
}

.rent-tariff-details-modal {
  .el-dialog__body {
    padding: 0;
  }

  .status-wrapper {
    align-items: center;
  }
}
