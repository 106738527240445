
@import "@variables";

.console-logs {
  white-space: pre-wrap;
  word-break: break-word;
  background: $lighter-gray;
  padding: 0 20px;
  height: 400px;
  width: 70%;
  margin-top: 30px;
  overflow-y: scroll;
}
