
.add-bulk {
  padding: 24px 0;

  .doc-example {
    align-items: center;
    height: 20px;
    width: 54px;
    border-radius: 3px;
    margin-right: 5px;
    justify-content: center;
    text-decoration: none;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    span {
      font-size: 13px;
    }
  }

  .el-button {
    line-height: 22px;
    height: 40px;
    width: fit-content;
    padding: 0 30px !important;
  }

  .title > span {
    display: block;
    margin-bottom: 12px;
  }

  .title {
    text-align: center;
    word-break: break-word;

    .template_message-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }

    .template_message-wrapper > span {
      margin: 0 5px;
    }

    .upload-wrapper {
      margin-bottom: 25px;
      justify-content: center;
      flex-direction: column;

      .file-name {
        font-weight: 600;
        text-decoration: underline;
        line-height: 25px;
        margin-top: 5px;
      }

      span {
        display: block;
      }

      input {
        display: none;
      }
    }
  }
}
