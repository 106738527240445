
@import "@variables";

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .bike-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
