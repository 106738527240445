
.add-rent-tariff {
  .el-form {
    padding: 0 60px;

    .el-form-item {
      margin-bottom: 0;
    }
  }

  .fee {
    display: flex;
    align-items: center;

    h3 {
      font-size: 20px !important;
      letter-spacing: 0;
      line-height: 27px;
      font-weight: 500;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }

    .el-input {
      width: 64px;
    }
  }

  .fees-block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
