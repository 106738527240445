
@import "@variables";

.rides-wrapper {
  margin-top: 0 !important;
}

.extra-charge-btn{
  border-radius: 50px;
}

.show-tickets-wrapper {
  margin: 32px 0;

  .view-tickets-btn,
  .see-contact-details-btn,
  .extra-charge-btn,
  .restrict-btn,
  .unrestrict-btn,
  .green-btn,
  .device-info-btn {
    height: 36px;

    h4 {
      font-size: 14px !important;
      font-weight: 500 !important;
      letter-spacing: 0.1px;
      line-height: 13px;
    }
  }
}

.customer-view {
  .user-info-filter {
    margin-top: 36px;
    align-items: center;

    .user-info-wrapper {
      align-items: center;

      .profile-pic {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 26px;
      }

      .user-info {
        h4 {
          color: $secondary;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 25px;
        }
      }
    }
  }
}
