
.start-bike-maintenance {
  flex-direction: column;
  align-items: center;

  .el-button {
    line-height: 22px;
    height: 40px;
    width: fit-content;
    padding: 9px 27px !important;
  }

  .title {
    display: block;
    word-break: break-word;
  }

  .status-wrapper {
    flex-direction: column;
    margin: 24px 0;

    .status-title {
      margin-bottom: 14px;
    }

    .status-switch {
      align-items: center;
      width: 120px;

      span {
        margin-left: 4px;
      }
    }
  }
}
