
.export-maintenanaces {
  padding: 24px 0;
  // margin-top: 40px;
  min-width: 99%;

  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  justify-content: center !important;
  align-items: center !important;

  background-color: #eff2fa;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}

.filter-date-picker {
  margin: auto;
  
}
.filter-cost-center {
  width: 85%;
  margin: auto;
}

.export-btn {
  margin: auto;
  margin-top: 15px;
}
