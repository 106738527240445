
.status-wrapper {
  display: flex;
  align-items: center;
  word-break: keep-all !important;
  
  .oval{
    margin-left: 10px;
  }
}

.true {
  background-color: lightgreen;
  color: darkgreen;
  font-weight: bold;
  border-radius: 6px;
  padding: 5px;
}
.false {
  background-color: lightcoral;
  color: darkred;
  font-weight: bold;
  border-radius: 6px;
  padding: 5px;
}

.noClick {
  pointer-events: none;
}
