
.status-wrapper {
  display: flex;
  align-items: center;
  word-break: keep-all !important;
}

.grid-buttons {
  display: inline-grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }
}

.disabled-img {
  opacity: 0.5;
  cursor: not-allowed;
}

