
@import "@variables";

.view-bike-details {
  .finish-btn {
    height: 40px;
    width: 160px;
    box-shadow: 0 10px 20px 0 $perano-transparent;
  }

  textarea {
    max-height: 120px;
  }

  .details-bold .el-col span {
    line-height: 41px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
