
.status-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    font-size: 16px;
  }

  .status {
    margin-left: 5px;
  }
}
