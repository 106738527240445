
.profile-pic {
  align-items: center;

  img {
    margin-right: 30px;
  }
}
.custom-border-switch .el-switch__core {
  border: 2px solid #000; /* Add your desired border style here */
  border-radius: 12px; /* Adjust border-radius if needed */
}

.custom-border-switch .el-switch__button {
  border: 2px solid #000 !important; /* Add border to the dot */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Add shadow to the dot */
  background-color: #fff; /* Change background color of the dot */
}

/* Ensuring specificity and visibility of the dot (thumb) */
.custom-border-switch .el-switch__button::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 50%;
}
