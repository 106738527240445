
@import "@variables";

.avatar {
  width: 146px;
  height: 146px;
  object-fit: cover;
}

.settings-btn {
  border-radius: 7px !important;
  background: $tab-view-color !important;
}
