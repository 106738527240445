
.tickets-view {
  margin-top: 0 !important;

  .tickets-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}

.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}

.rotated-clip {
  transform: rotate(90deg);
}
