@import "@variables";

@font-face {
  src: url("../assets/fonts/AvenirNextW10-Medium.otf");
  font-family: "AvenirNextW10Medium";
}

@font-face {
  src: url("../assets/fonts/AvenirNextLTPro-Bold.otf");
  font-family: "AvenirNextLTProBold";
}

@font-face {
  src: url("../assets/fonts/AvenirNextLTPro-Demi.otf");
  font-family: "AvenirNextLTProDemi";
}

@font-face {
  src: url("../assets/fonts/AvenirNextLTPro-Regular.otf");
  font-family: "AvenirNextLTProRegular";
}

body {
  background: $body-background;
}

#app {
  font-family: "AvenirNextW10Medium", sans-serif;
}
