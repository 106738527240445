
@import "@variables";

.docking-stations-chart {
  align-items: center;
}

.docking-stations-map {
  .custom-legend {
    flex-direction: column;
    min-width: 130px;

    img {
      height: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .statistics {
    margin: 24px 0;

    .statistic {
      align-items: center;
      width: 244px;
      padding: 18px 25px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-right: 24px;
    }
  }
}
