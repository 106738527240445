
.view-payment-actions {
  flex-direction: column;
  align-items: center;

  .maxim-refund {
    margin-left: 20px;
  }

  .el-button + .el-button {
    margin-left: 0;
  }

  .refund {
    .el-input-number {
      margin-left: 20px;
      width: 80px;
    }
  }

  button {
    padding: 0 18.5px !important;
    line-height: 22px;
    height: 40px;
    margin-bottom: 24px;

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}
