
.status-wrapper {
  display: flex;
  flex-direction: column;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
