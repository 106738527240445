
@import "@variables";

.statistic-info {
  margin-left: 16px;

  h1 {
    font-size: 32px !important;
    line-height: 44px;
    font-family: $avenir-next-regular;
  }

  span {
    color: #7f7f7f;
  }
}
