
@import "@variables";

.view-password-validation-delete {
  flex-direction: column;

  .delete-message {
    span {
      display: block;
      font-family: $avenir-next-regular;
      font-size: 15px;
      line-height: 25px;
      color: $secondary;
    }
  }

  .password-validation-delete-btn {
    box-shadow: 0 10px 20px 0 rgba(228, 30, 38, 0.21);
    color: white !important;
    background: #e41e26 !important;
  }
}
