
@import "@variables";

// .el-popper.is-customized {
//   /* Set padding to ensure the height is 32px */
//   padding: 6px 12px;
//   background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
// }

// .el-popper.is-customized .el-popper__arrow::before {
//   background: linear-gradient(45deg, #b2e68d, #bce689);
//   right: 0;
// }

/* Global styles for customized tooltip */
.customized-tooltip >>> .el-tooltip__popper {
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.customized-tooltip >>> .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 3px rgb(238, 238, 238);
  // border-style: solid;
  // border: 1px solid grey;
  padding: 10px;
  padding-left: 15px;
  position: absolute;
  z-index: 100;
  top: -200%;
  right: 160%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  font-family: "AvenirNextW10Medium", sans-serif;
  // font-family: Montserat, sans-serif;
  // font-family: "AvenirNextW10Medium", sans-serif;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -9px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent rgb(255, 255, 255);
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  cursor: pointer;
}

.unlock-warning {
  display: block;

  i {
    font-size: 35px;
    color: $warning;
  }
}

.grid-buttons {
  display: inline-grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }
}
