
.forgot-password-view{
  display: flex;

  h4 {
    color: rgba(6, 16, 88, 0.6) !important;
    font-family: Avenir, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 100px;
  }
}
