
.wrapper {
  background-color: white;
  border-radius: 10px;
}
.status-wrapper {
  display: flex;
  align-items: center;
}

.ticket-details > * {
  margin: 10px;
}

.ticket-data > * {
  margin: 10px;
}
