
@import "@variables";

.upload-wrapper {
  align-items: center;
  margin-bottom: 20px;
}

.file-name {
  margin-left: 10px;
  color: #606266;
}

.submit-button {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
}

