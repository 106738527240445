
.view-customer-tickets-details-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog--center .el-dialog__body {
    padding: 0 !important;
  }

  .dialog {
    max-height: 500px;
  }
}
