
.gdpr-message {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper {
  height: 293px;
  margin: 23px auto 40px;
  width: 100%;
}

.view-ride-details {
  padding-bottom: 24px;

  .price-plans {
    span {
      display: block;
      overflow-wrap: break-word;
    }
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
