
.edit-docking-station {
  h4 {
    overflow: hidden;
    color: white;
    font-family: Montserat, sans-serif;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 19px;
  }
}

.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
